import axios from "axios";
import data from "../../../utils/constant";

export const postDataFunc = async (resourceUrl, payLoad) => {
  let responseData = [];

  await axios
    .post(`${data.host}${resourceUrl}`, payLoad, {
      headers: data.headers
    })
    .then(response => {

        if (response?.data?.data?.error) responseData = response?.data?.data.responseBody;
        else responseData = response?.data?.data?.responseBody;
     


    })
    .catch(error => {
      responseData = error.response.data.responseBody || error.message ||error;
      throw responseData;
    });

  return responseData;
}